import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: Turkish Get Ups & Hanging L Holds`}</p>
    <p>{`then, 8:00 EMOM of:`}</p>
    <p>{`15 second hanging L Hold`}</p>
    <p>{`15 squats`}</p>
    <p>{`rest 3:00 then,`}</p>
    <p>{`4 Rounds for time of:`}</p>
    <p>{`10-Ring Dips`}</p>
    <p>{`15-Pullups`}</p>
    <p>{`20-Situps`}</p>
    <p><strong parentName="p">{`*`}{`CrossFit the Ville’s Barbell Club will be starting January 19th!  If
you’d like to improve your Clean & Jerk and Snatch these classes are for
you.  Classes will be offered Saturday’s (11:15) and Sunday’s (1:45)
each weekend after our CrossFit classes.  Email Daniel for more
information.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      